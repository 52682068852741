import { IIncomeAndExpenseSummaries } from "@/models/Documents";

class DocumentsInvoices {
  mapIncomeAndExpenseSummaries(
    summaries: IIncomeAndExpenseSummaries[]
  ): IIncomeAndExpenseSummaries[] {
    return (
      // We assume creation date always will be 1 year (2025) after values inside document (2024), so we hardcoded date to previous year last day (2024-12-31) https://housfy-docs.atlassian.net/browse/IT-10666?focusedCommentId=56430
      summaries.map((summary) => {
        const lastYearMonth = 11; // An integer representing the month: 0 for January, 1 for February, and so on. (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setMonth#monthvalue)
        const lastYearDay = 31;
        const date = new Date(summary.date);
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(lastYearMonth, lastYearDay);
        return {
          ...summary,
          uploadDate: summary.date,
          date: date.toISOString(),
        };
      }) || []
    );
  }
}

export default new DocumentsInvoices();
